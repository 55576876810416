import React from 'react';
import './Loading.scss';

const Loading = ({text = 'Loading more products...'}) => (
  <div className="loading-container">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="loading-text">
      <span>{text}</span>
    </div>
  </div>
);

export default Loading;
