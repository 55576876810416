import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { ProductsFilter } from '../components/Products/ProductsFilter';
import './search.scss';

export default class Search extends React.Component {
  render() {
    const {
      data,
      location
    } = this.props;
    const {
      page,
      productLinks,
      supplierLinks,
      site: { siteMetadata: { siteTitle } }
    } = data;
    const {
      pageTitle,
      yoast
    } = page;
    const searchBarQuery = location.state && location.state.searchBarQuery
      ? location.state.searchBarQuery
      : null;
    return (
      <Layout location={location} pageTitle={pageTitle} className="search-page-template">
        <SEO
          title="Search Our Products | HIFraser"
          desc={yoast.metaDescription}
        />
        <ProductsFilter
          query={searchBarQuery}
          location={location}
          productLinks={productLinks.nodes}
          supplierLinks={supplierLinks.nodes}
        />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query SearchPageQuery($id: String!) {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    page: wordpressPage(id: { eq: $id }) {
      pageId: wordpress_id
      pageTitle: title
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    productLinks: allWordpressWpProductLink {
      nodes {
        slug
        title
        acf {
          email
          url {
            url
            title
            target
          }
        } 
      }
    }
    supplierLinks: allWordpressWpSupplierLink {
      nodes {
        slug
        title
        acf {
          url {
            target
            title
            url
          }
        }
      }
    }
  }
`
