import React, { Component, useState } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Loading from '../Loading';
import { getUrlVars, childCategories, compileQuery } from '../../utils/helpers';
import SearchBar from '../SearchBar';
import ProductsList from './ProductsList';
import Pagination from './Pagination';
import GatsbyLink from '../GatsbyLink'
import './ProductsFilter.scss';
import '../Pagination/Pagination.scss';

class ThisProductsFilter extends Component {
  constructor(props) {
    const pageAttr = getUrlVars()
    super(props);
    this.state = {
      loading: true,
      products: [],
      error: null,
      search: props.query || '',
      holdSearchVal: null,
      activeCategoryId: props.activeCategoryId ? props.activeCategoryId : null,
      activeSupplierId: props.activeSupplierId ? props.activeSupplierId : null,
      totalProductCount: 0,
      totalProductPages: 0,
      page: parseInt(pageAttr.page) > 1 ? parseInt(pageAttr.page) : 1
    }
  }

  componentDidMount() {
    this.getProducts();
  }

  handleUpdate = (e, page, pageTo) => {
    e.preventDefault()
    const { location } = this.props;
    this.setState({
      page: page
    }, () => {
      this.getProducts();
      navigate(`${location.pathname}${pageTo}`)
    });
  }

  handleSearchSubmit = ( e ) => {
    e.preventDefault()
    this.setState({
      page: 1,
      holdSearchVal: e.target.elements.search.value
    }, () => {
      this.getProducts();
      //navigate(`${location.pathname}${compileQuery(null, null, 'page')}`);
    });
  }

  handleNavigationWithContext = (e, link) => {
    e.preventDefault();
    this.setState({
      page: link,
    }, () => {
      this.scrollIntoView(e, 'layout')
      this.getProducts();
    });
  };

  handleSearchBarUpdate = (e) => {
    const { location } = this.props;
    this.setState({
      search: e.target.value,
    })
  }

  scrollIntoView = (event, containerId) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      containerId = containerId.replace('#','')
      const results = document.getElementById(containerId) ? document.getElementById(containerId) : null;
      results && results.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  getProducts = () => {
    const pageAttr = getUrlVars()
    const { page, activeSupplierId } = this.state
    const supplier = activeSupplierId ? `&supplier=${activeSupplierId}` : ''
    const productsUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/catalogue/v4/api?search=${encodeURI(this.state.search)}&page=${page}${supplier}`
    this.setState({ loading: true });
    fetch(productsUrl)
      .then(async (res) => {
        const total = res.headers.get( 'x-wp-total' )
        const totalpages = res.headers.get( 'x-wp-totalpages' )
        const data = await res.json();
        this.setState({ loading: false, products: data, totalProductCount: total, totalProductPages: totalpages, error:null });
      })
      .catch(error => {
        this.setState({ loading: false, error });
      })
  }

  render() {
    const { products, loading, commodity, sector, search, activeCategoryId, activeSupplierId, error, totalProductCount, holdSearchVal, page } = this.state
    const { hideProductList = false, location, activeSupplierName, query, productLinks, supplierLinks } = this.props;
    const pageAttr = getUrlVars()
    let filterProducts = products;

    if (pageAttr) {
      Object.keys(pageAttr).forEach(att => {
        if (att !== 'page') {
          var attId = this.state[att] && this.state[att].find(e => e.node.slug === pageAttr[att] )
          // Get tax ID
          if (attId && attId.wordpress_id) {
            filterProducts = filterProducts.filter( i => i[att].find(c => c === parseInt(attId.wordpress_id) ) )
          }
        }}
      )
    }

    const paged = page;
    const itemCount = totalProductCount;
    const perPage = 100; //Match to wp api
    const numberOfPages = Math.ceil(itemCount / perPage);
    const nextPage = paged ? ( parseInt(paged) + 1 ) : 2;
    const prevPage = paged ? parseInt(paged) - 1 : null;
    const range = paged ? perPage * paged : perPage;
    var pages = [];
    for (var i = 0; i < numberOfPages; i++) {
          pages.push(i+1);
    }
    const showRangeMin = Number(paged - 5);
    const showRangeMax = Number(paged + 5);

    return (
      <div className="products-filter-container">
        <div className="wrapper">
          <form onSubmit={event => this.handleSearchSubmit(event)}>
            <div className={`search-bar ${search ? 'active' : ''}`}>
              <div className="input-container">
                <input
                  onChange={event => this.handleSearchBarUpdate(event)}
                  id="searchbar"
                  type="text"
                  name="search"
                  value={search}
                  autoComplete='off'
                />
                <label htmlFor="searchbar" className="placeholder">Search all products</label>
                <button
                  className="search-icon"
                  //onClick={() => navigateTo ? navigate(navigateTo, {state: {searchBarQuery}}) : null}
                />
              </div>
            </div>
          </form>
          <div className="search-text">
            <h2>Product search results</h2>
            {!loading && <p>Page {paged ? paged : 1} of {numberOfPages} - Total results {itemCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</p>}
          </div>
          {filterProducts && !hideProductList && (
            <>
              <ProductsList
                loading={loading}
                products={filterProducts}
                range={range}
                loadedItemCount={products.length}
                productLinks={productLinks}
                supplierLinks={supplierLinks}
                perPage={perPage}
                data={this.state}
                error={error}
                location={location}
              />

              <nav className="pagination" role="navigation">
                <div className="navbar navbar-menu">
                  {paged != 1 &&
                    <GatsbyLink 
                      onClick={(event) => this.handleNavigationWithContext(event, prevPage)}
                      className="prev" 
                    />
                  }

                  {showRangeMin > 0 &&
                    <>
                      <GatsbyLink 
                        onClick={(event) => this.handleNavigationWithContext(event, parseInt(1))}
                      >1</GatsbyLink>
                      <span>...</span>
                    </>
                  } 

                  {
                    pages.map((key, index) => {
                      const isActive =  parseInt(paged) === key;
                      if (key > showRangeMin && key < showRangeMax) {
                        return (
                          <GatsbyLink 
                            key={index} 
                            className={isActive ? 'active' : '' }
                            onClick={(event) => this.handleNavigationWithContext(event, parseInt(key))}
                          >{key}</GatsbyLink>
                        )
                      }
                    })
                  }

                  {showRangeMax < numberOfPages &&
                    <>
                      <span>...</span>
                      <GatsbyLink 
                          onClick={(event) => this.handleNavigationWithContext(event, parseInt(numberOfPages))}
                      >{numberOfPages}</GatsbyLink>
                    </>
                  }

                  {paged != numberOfPages && numberOfPages > 1 &&
                    <GatsbyLink 
                      onClick={(event) => this.handleNavigationWithContext(event, nextPage)}
                      className="next" 
                    />
                  }
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    );
  }
}


export const ProductsFilter = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <ThisProductsFilter {...props} data={data} />}
    />
  );
}
