import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import './SearchBar.scss';

const ProductSearchForm = (props) => {
  const [searchBarQuery, setSearchBarQuery] = useState('');
  const searchbarEl = useRef(null);
  const {
    navigateTo = null,
    value = null,
    onChange = null, // Pass in a function to call on input update
    placeholder = "Find what you're looking for"
  } = props;
  const onEnterPress = (e) => {
    if (e.key === "Enter" && navigateTo) navigate(navigateTo, {state: {searchBarQuery}});
  }
  useEffect(() => {
    // On mount
    searchbarEl.current.addEventListener('keyup', onEnterPress);
    return () => {
      // On unmount
      searchbarEl.current.removeEventListener('keyup', onEnterPress);
    };
  });
  return (
    <div className={`search-bar ${searchBarQuery || value ? 'active' : ''}`}>
      <div className="input-container">
        <input
          onChange={(e) => {
            setSearchBarQuery(e.target.value);
            if (onChange) onChange(e);
          }}
          id="searchbar"
          type="text"
          name="search"
          value={value || searchBarQuery}
          autoComplete='off'
          ref={searchbarEl}
        />
        <label htmlFor="searchbar" className="placeholder">{placeholder}</label>
        {!navigateTo ? (
          <span className="search-icon" />
        ) : (
          <button
            type="button"
            className="search-icon"
            onClick={() => navigateTo ? navigate(navigateTo, {state: {searchBarQuery}}) : null}
            onKeyDown={() => navigateTo ? navigate(navigateTo, {state: {searchBarQuery}}) : null}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSearchForm;
