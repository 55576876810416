import React, { Component } from 'react';
import Loading from '../Loading';
import { decodeEntities } from '../../utils/htmlParse';
import ProductPopup from './ProductPopup';
import ProductEnquiry from '../Forms/ProductEnquiry';
import './ProductsList.scss';

const ListItem = ({ item, showProductPopup }) => {
  const {
    slug,
    partNumber,
    description,
    categoryId,
    supplierId
  } = item;
  return (
    <div className="products-list-item">
      <div className="products-list-item-col product-part-number">
        <a href={`/product/${slug}/`} onClick={e => showProductPopup(e, partNumber, description, categoryId, supplierId)}>
          {partNumber ? decodeEntities(partNumber) : ''}
        </a>
      </div>
      <div className="products-list-item-col product-description">
        {description ? decodeEntities(description) : ''}
      </div>
      
    </div>
  )
}

export default class ProductsList extends Component {
  state = {
    showModal: false,
    partNumber: null,
    description: null,
    productLink: null,
    supplierLink: null
  }

  showProductPopup = (e, partNumber, description, categoryId, supplierId) => {
    e.preventDefault();
    const { productLinks, supplierLinks } = this.props;
    const supplierLinkObject = supplierLinks.find(a => parseInt(a.title) === parseInt(supplierId))
    const defaultSupplierLink = supplierLinks.find(a => a.title === 'Default')
    const supplierLink = supplierLinkObject?.acf?.url?.url ? supplierLinkObject.acf :  defaultSupplierLink.acf

    const productLinkObject = productLinks.find(a => parseInt(a.title) === parseInt(categoryId));
    const defaultLinkObject = productLinks.find(a => a.title === 'Default');
    const productLink = productLinkObject?.acf?.url?.url ? productLinkObject.acf : defaultLinkObject.acf

    //let currProductCat = productCategories.find(c => c.acf.wordpress_id === categoryId);
    //let productCatSlug = currProductCat?.slug;
    //while(currProductCat?.acf.parentCategoryId) {
    //  currProductCat = productCategories.find(c => c.acf.wordpress_id === currProductCat.acf.parentCategoryId); // eslint-disable-line
    //  productCatSlug = `${currProductCat.slug}/${productCatSlug}`;
    //}

    this.setState({
      showModal: true,
      partNumber,
      description,
      productLink,
      supplierLink
    });
    document.querySelector('body').classList.add('popup-open');
  }

  closeProductPopup = (e) => {
    e.preventDefault();
    this.setState({ showModal: false });
    document.querySelector('body').classList.remove('popup-open');
  }

  render() {
    const {
      range,
      perPage,
      data,
      products,
      pageContext,
      loadedItemCount,
      loading,
      error,
      location
    } = this.props;

    const { showModal, partNumber, description, productLink, supplierLink } = this.state;

    if (!loading && (!products || products.length < 1)) return <div className="products-list no-products">There are no products to show</div>;
    return (
      <>
        <div className="products-list">
          {loading &&
            <Loading text="Loading products..."/>
          }
          <div className="products-list-header">
            <div className="products-list-header-col">Part No.</div>
            <div className="products-list-header-col">Description</div>
          </div>
          <div className="products-list-body">
            
            {
              // Show products in range
              products && products.map((product, index) => (

                  <ListItem
                    key={index}
                    item={product}
                    index={index}
                    showProductPopup={this.showProductPopup}
                  />

              ))
            }
            {!loading && error && (
              <div style={{padding: '40px 0px'}}>
                <div>
                  Error: Sorry, we were unable to retrieve more products from our system.
                </div>
                <div>
                  Please try again later.
                </div>
              </div>
            )}
          </div>
        </div>
        <ProductPopup
          active={showModal}
          closeModal={this.closeProductPopup}
        >
          <div className="content-wrapper">
            <div className="part-number-specifications">
              <span className="part-number-heading">Part Number Specifications</span>
              <div className="part-number-table">
                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">Product</div>
                  <div className="part-number-table-row-content">Information</div>
                </div>
                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">Part Number</div>
                  <div className="part-number-table-row-content"><span>{partNumber}</span></div>
                </div>
                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">Description</div>
                  <div className="part-number-table-row-content"><span>{description}</span></div>
                </div>
                {/*
                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">Supplier</div>
                  <div className="part-number-table-row-content"><span>{supplierLink?.url?.title}</span></div>
                </div>
                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">More info</div>
                  <div className="part-number-table-row-content"><span><a href={productLink?.url?.url} target="_blank">{productLink?.url?.title}</a></span></div>
                </div>

                <div className="part-number-table-row">
                  <div className="part-number-table-row-heading">Email:</div>
                  <div className="part-number-table-row-content">
                    <span>{productLink?.email}</span>
                  </div>
                </div>
                */}
              </div>
            </div>
            <div className="enquiry-form-container">
              <span className="enquiry-form-heading">Enquire now</span>
              <ProductEnquiry
                location={location}
                partNumber={partNumber}
                partDescription={description}
                productLink={productLink}
                sendTo={productLink?.email}
                inPopup
              />
            </div>
          </div>
        </ProductPopup>
      </>
    );
  }
}
